"use client";

import { useTextDirectionContext } from "../../../helpers/TextDirectionContext";
import classNames from "classnames/bind";
import { useEffect, useRef, useState, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { useObserveElementRef } from "../../../helpers/in-viewport/in-viewport";
import { notNullish } from "../../../helpers/notNullish";
import { createLinkForCartoonsHomepage } from "../../../helpers/createLinkForCartoonsHomepage";
import { CosmosButton } from "@cosmos/web/react";
import { SliderButton } from "../../slider-button/slider-button";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import styles from "./cartoons-occasion-stack.module.css";
import { useLocaleContext } from "../../../helpers/LocaleContext";
import { TimeOfDay } from "./cartoons-occasion-stack.types";
import { EmblaOptionsType, EmblaCarouselType } from "embla-carousel";

const cx = classNames.bind(styles);

type BackgroundAssetType = "landscape" | "cityscape";

type Slide = {
  id: string;
  title: string | null;
  description: string | null;
  imageUrl?: string | null;
  timeOfDay: TimeOfDay;
  crepoOrder?: string | null;
  crepoDaySegment: string; // "morning1" | "morning2" | "noon1" | "noon2" | "evening1" | "evening2" | "night1" | "night2";
};

/**
 * When you scroll the stack into the view, the rail should animate to the slide
 * associated with the user's current time-of-day. This function finds the first
 * card that matches the browser's date (time) and returns its index.
 */
const getStartIndexFromTimeOfDay = (slides: Slide[]) => {
  const hoursElapsed = new Date().getHours();
  const NOON = 12;
  const EVENING = 17;
  const NIGHT = 20;

  if (!hoursElapsed) {
    return;
  }

  if (hoursElapsed < NOON) {
    return 1; // to trigger an animation we need to differentiate from initial state
  }

  if (hoursElapsed < EVENING) {
    return slides?.findIndex((slide) => slide.timeOfDay === "noon");
  }

  if (hoursElapsed < NIGHT) {
    return slides?.findIndex((slide) => slide.timeOfDay === "evening");
  }

  return slides?.findIndex((slide) => slide.timeOfDay === "night");
};

export const CartoonsOccasionStack = ({
  items,
  title,
  description,
  buttonText,
}: {
  items: Slide[];
  title: string | null;
  description: string | null;
  buttonText: string | null;
}) => {
  const textDirection = useTextDirectionContext();
  const locale = useLocaleContext();

  // Foreground and Background elements move in different directions, this needs to be swapped for RTL languages
  const landscapeDir = textDirection === "rtl" ? "ltr" : "rtl";
  const cityscapeDir = textDirection;
  const mainCarouselDir = textDirection;

  const slides =
    items.sort((a, b) => {
      // Sort by "morning1" (then sub sort by "order"), morning2 (order), noon1 (order)...

      // Define the order of crepoDaySegment groups
      const crepoDaySegmentOrder: { [key: string]: number } = {
        morning1: 0,
        morning2: 1,
        noon1: 2,
        noon2: 3,
        evening1: 4,
        evening2: 5,
        night1: 6,
        night2: 7,
      };

      // Get the order for each crepoDaySegment
      const aCrepoDaySegmentOrder = crepoDaySegmentOrder[a.crepoDaySegment];
      const bCrepoDaySegmentOrder = crepoDaySegmentOrder[b.crepoDaySegment];

      // If crepoDaySegment is the same, sort by crepoOrder
      if (aCrepoDaySegmentOrder === bCrepoDaySegmentOrder) {
        const aCrepoOrder = a.crepoOrder || "1";
        const bCrepoOrder = b.crepoOrder || "1";
        return aCrepoOrder.localeCompare(bCrepoOrder); // Sort alphabetically
      }

      // Otherwise, sort by crepoDaySegment order
      return aCrepoDaySegmentOrder - bCrepoDaySegmentOrder;
    }) ?? [];

  const [mainCarouselRef, mainCarousel] = useEmblaCarousel({
    duration: 50,
    loop: true,
    watchDrag: true,
    direction: mainCarouselDir,
  } as EmblaOptionsType);

  const [cityscapeCarouselRef, cityscapeCarousel] = useEmblaCarousel({
    duration: 50,
    loop: true,
    watchDrag: false,
    direction: cityscapeDir,
  } as EmblaOptionsType);

  const [landscapeCarouselRef, landscapeCarousel] = useEmblaCarousel({
    duration: 50,
    loop: true,
    watchDrag: false,
    direction: landscapeDir,
  } as EmblaOptionsType);

  // Embla selected/scroll
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  // Embla Prev button
  const onPrevButtonClick = useCallback(() => {
    if (!mainCarousel) return;
    mainCarousel.scrollPrev();
  }, [mainCarousel]);

  // Embla Next button
  const onNextButtonClick = useCallback(() => {
    if (!mainCarousel) return;
    mainCarousel.scrollNext();
  }, [mainCarousel]);

  // Embla onSelect
  const onSelect = useCallback(
    (mainCarousel: EmblaCarouselType) => {
      const selectedScrollSnap = mainCarousel?.selectedScrollSnap();
      cityscapeCarousel?.scrollTo(selectedScrollSnap);
      landscapeCarousel?.scrollTo(selectedScrollSnap);
      setSelectedIndex(selectedScrollSnap);
    },
    [cityscapeCarousel, landscapeCarousel],
  );

  // Embla useEffect
  useEffect(() => {
    if (!mainCarousel) return;

    onSelect(mainCarousel);
    mainCarousel.on("reInit", onSelect);
    mainCarousel.on("select", onSelect);
  }, [mainCarousel, onSelect]);

  const getCartoonsAssetUrl = (
    key: string,
    options: { width?: number } = {},
  ) => {
    const transforms = ["f_auto"];
    if (options.width) {
      transforms.push(`w_${options.width}`);
    }
    const transformsString = transforms.join(",");
    return `https://res.cloudinary.com/hoiqxhyiy/image/fetch/${transformsString}/https://static-cartoons.redbull.com/occasionReel/occasionReel-${key}.png`;
  };

  const getBackgroundImageSrcSet = ({
    type,
    timeOfDay,
    nextTimeOfDay,
  }: {
    type: BackgroundAssetType;
    timeOfDay: TimeOfDay;
    nextTimeOfDay: TimeOfDay;
  }): string => {
    const variant = timeOfDay === nextTimeOfDay ? "1" : "2";

    /**
     * These sizes are more-or-less arbitrary, they were chosen because they
     * represent some typical viewport widths according to:
     * https://gs.statcounter.com/screen-resolution-stats
     *
     * More options results in more granular performance optimisation, but at the
     * cost of cache-hit probability.
     */
    const TYPICAL_VIEWPORT_WIDTHS = [
      320, 480, 768, 1024, 1200, 1366, 1440, 1536, 1920, 2560,
    ];

    return TYPICAL_VIEWPORT_WIDTHS.map((width) => {
      const url = getCartoonsAssetUrl(`${type}-${timeOfDay}${variant}`, {
        width,
      });

      return `${url} ${width}w`;
    }).join(", ");
  };

  const { ref, hasIntersected } = useObserveElementRef<HTMLDivElement>({
    threshold: 0,
  });

  const startIndexRef = useRef(getStartIndexFromTimeOfDay(slides));

  useEffect(() => {
    if (hasIntersected && mainCarousel) {
      const index = startIndexRef.current;
      if (notNullish(index) && index !== -1) {
        mainCarousel.scrollTo(index);
      }
    }
  }, [hasIntersected, mainCarousel]);

  const currentSlide = slides[selectedIndex];

  /**
   * You might ask - isn't this redundant? Isn't this the whole point of the
   * "loading=lazy" attribute?
   *
   * The issue is carousels might be scrolled *vertically* into the viewport,
   * but images that are *horizontally* positioned offscreen will still not
   * load until they're moved into view by interacting with the carousel.
   */
  const imageLoading = hasIntersected ? "eager" : "lazy";
  const morningCount = slides.filter(
    (element) => element.timeOfDay === "morning",
  ).length;
  const noonCount = slides.filter(
    (element) => element.timeOfDay === "noon",
  ).length;
  const eveningCount = slides.filter(
    (element) => element.timeOfDay === "evening",
  ).length;
  // const nightCount = slides.filter((element) => element.timeOfDay === "night").length;

  const arrayIncludingMorningLength = morningCount;
  const arrayIncludingNoonLength = morningCount + noonCount;
  const arrayIncludingEveningLength = morningCount + noonCount + eveningCount;
  // const arrayIncludingNightLength = morningCount + noonCount + eveningCount + nightCount;

  /**
   *  TODO / BUG / HOTFIX
   *  se-sv had an issue where it would critically fail due to an empty array
   */
  if (!items || items.length === 0) {
    console.warn(
      "Error loading Cartoons Occasion Stack, array is empty or unedfined",
    );
    return null;
  }

  return (
    <div
      ref={ref}
      style={{
        "--_slide-timeofday-morning-index": selectedIndex + 1,
        "--_slide-timeofday-noon-index":
          selectedIndex + 1 - arrayIncludingMorningLength,
        "--_slide-timeofday-evening-index":
          selectedIndex + 1 - arrayIncludingNoonLength,
        "--_slide-timeofday-night-index":
          selectedIndex + 1 - arrayIncludingEveningLength,
      }}
      className={cx("container", `container--${currentSlide.timeOfDay}`)}
    >
      <div className={cx("inner")}>
        <div className={cx("content-intro")}>
          {title && <h2 className={cx("title")}>{title}</h2>}
          {description && <p className={cx("description")}>{description}</p>}
        </div>
        <div className={cx("carousels")}>
          <div className={cx("background-gradient-wrapper")} aria-hidden="true">
            <div
              className={cx(
                "background-gradient",
                `background-gradient--morning`,
                {
                  "background-gradient--is-active":
                    currentSlide.timeOfDay === "morning",
                },
              )}
            ></div>
            <div
              className={cx(
                "background-gradient",
                `background-gradient--noon`,
                {
                  "background-gradient--is-active":
                    currentSlide.timeOfDay === "noon",
                },
              )}
            ></div>
            <div
              className={cx(
                "background-gradient",
                `background-gradient--evening`,
                {
                  "background-gradient--is-active":
                    currentSlide.timeOfDay === "evening",
                },
              )}
            ></div>
            <div
              className={cx(
                "background-gradient",
                `background-gradient--night`,
                {
                  "background-gradient--is-active":
                    currentSlide.timeOfDay === "night",
                },
              )}
            ></div>
          </div>
          <div className={cx("header")}>
            <div className={cx("background-clouds")} aria-hidden="true">
              {(() => {
                const arr = [];
                const arrFilename = [
                  1, 2, 3, 4, 5,
                ]; /* cycle though these filenames if we have more than 5 clouds */
                for (let i = 0; i < 8; i++) {
                  arr.push(
                    <img
                      key={i}
                      src={getCartoonsAssetUrl(
                        `cloud${(i % arrFilename.length) + 1}`,
                      )}
                      alt=""
                      aria-hidden="true"
                      className={cx(
                        "background-cloud",
                        `background-cloud--${i + 1}`,
                      )}
                      loading={imageLoading}
                    />,
                  );
                }
                return arr;
              })()}
            </div>
            <div className={cx("satellites")} aria-hidden="true">
              <div className={cx("satellite", "satellite--sun")}>
                <img
                  className={cx("satellite-img")}
                  alt=""
                  src={getCartoonsAssetUrl("sun")}
                  loading={imageLoading}
                />
              </div>
              <div className={cx("satellite", "satellite--moon")}>
                <img
                  className={cx("satellite-img")}
                  alt=""
                  src={getCartoonsAssetUrl("moon")}
                  loading={imageLoading}
                />
              </div>
            </div>
            {/* ↓ Visually this is a H3, but set as a Div as it's only her for visual design, the real h3 is visually hidden within each slide */}
            <div aria-hidden="true" className={cx("slide-title")}>
              {/* We list all titles here, overlapping in a single cell Grid to force max height */}
              {slides.map((slide, index) => (
                <span
                  key={slide.id}
                  className={cx("slide-title-item", {
                    "slide-title-item--is-active": index === selectedIndex,
                  })}
                  hidden={selectedIndex === index ? false : true}
                >
                  {slide.title}
                </span>
              ))}
            </div>
          </div>
          <div className={cx("carousel-stage")}>
            <div
              className={cx(
                "backgrounds-wrapper",
                "backgrounds-wrapper--landscape",
              )}
              dir={landscapeDir}
              ref={landscapeCarouselRef}
              aria-hidden="true"
            >
              <div className={cx("backgrounds")}>
                {slides.map((slide, index) => (
                  <div key={slide.id} className={cx("background")}>
                    <img
                      className={cx("background-image")}
                      alt=""
                      loading={imageLoading}
                      srcSet={getBackgroundImageSrcSet({
                        type: "landscape",
                        timeOfDay: slide.timeOfDay,
                        nextTimeOfDay:
                          slides[(index + 1) % slides.length].timeOfDay,
                      })}
                      sizes="min(1222px, 100vw)"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div
              className={cx(
                "backgrounds-wrapper",
                "backgrounds-wrapper--cityscape",
              )}
              dir={cityscapeDir}
              ref={cityscapeCarouselRef}
              aria-hidden="true"
            >
              <div className={cx("backgrounds")}>
                {slides.map((slide, index) => (
                  <div key={slide.id} className={cx("background")}>
                    <img
                      className={cx("background-image")}
                      alt=""
                      loading={imageLoading}
                      srcSet={getBackgroundImageSrcSet({
                        type: "cityscape",
                        timeOfDay: slide.timeOfDay,
                        nextTimeOfDay:
                          slides[(index + 1) % slides.length].timeOfDay,
                      })}
                      sizes="min(1222px, 100vw)"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className={cx("carousel-wrapper")}>
              <div
                className={cx("carousel")}
                dir={mainCarouselDir}
                ref={mainCarouselRef}
              >
                <div className={cx("carousel-slides")}>
                  {slides.map((slide, index) => {
                    if (slide) {
                      return (
                        <div
                          data-index={index}
                          data-length={slides.length - 1}
                          data-current={selectedIndex}
                          className={cx("carousel-slide", {
                            "carousel-slide--not-active":
                              index !== selectedIndex,
                          })}
                          key={slide.id}
                        >
                          {/* Give each slide some semantic meaning */}
                          {slide.title && (
                            <h3
                              className={cx(
                                "carousel-title",
                                "carousel-title--sr-only",
                              )}
                            >
                              {slide.title}
                            </h3>
                          )}
                          {/* TODO: this link probably reads quite verbose in a screen reader */}
                          <a
                            className={cx("carousel-link")}
                            href={createLinkForCartoonsHomepage(locale)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {slide.imageUrl && (
                              <div
                                className={cx({
                                  "carousel-slide--prev-wiggle":
                                    selectedIndex === 0
                                      ? index === slides.length - 1
                                      : index === selectedIndex - 1,
                                  "carousel-slide--next-wiggle":
                                    selectedIndex === slides.length - 1
                                      ? index === 0
                                      : index === selectedIndex + 1,
                                })}
                              >
                                <img
                                  className={cx("carousel-image", {
                                    "carousel-image--small":
                                      index !== selectedIndex,
                                  })}
                                  srcSet={responsiveImageHelper.createCrepoSrcSet(
                                    slide.imageUrl,
                                    locale,
                                  )}
                                  sizes="(min-width: 720px) 201px, 192px"
                                  loading={imageLoading}
                                  alt={slide.title ?? ""}
                                />
                              </div>
                            )}
                            {/* CMS has a description, currently it's not visually shown */}
                            {slide.description && (
                              <span
                                className={cx(
                                  "carousel-description",
                                  "carousel-description--sr-only",
                                )}
                              >
                                {slide.description}
                              </span>
                            )}
                          </a>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <SliderButton
              buttonKind="cover-up"
              kind="previous"
              className={cx("slider-button", "slider-button--prev")}
              onClick={onPrevButtonClick}
              accessibilityLabel="occasion reel button to previous occasion"
            />
            <SliderButton
              buttonKind="cover-up"
              kind="next"
              className={cx("slider-button", "slider-button--next")}
              onClick={onNextButtonClick}
              accessibilityLabel="occasion reel button to next occasion"
            />
          </div>
          <div className={cx("button-wrapper")}>
            <CosmosButton
              href={createLinkForCartoonsHomepage(locale)}
              kind="cover-up"
              size="large"
              className={cx("button")}
            >
              {buttonText || "More Information"}
            </CosmosButton>
          </div>
        </div>
      </div>
    </div>
  );
};
